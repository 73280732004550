import React from 'react'
import { Toaster } from 'react-hot-toast'

export const ToasterContainer = () => {
  return (
    <Toaster
      position='bottom-center'
      toastOptions={{
        error: {
          style: {
            backgroundColor: 'red',
            color: '#ffffff',
          },
        },
        success: {
          style: {
            backgroundColor: 'green',
            color: '#ffffff',
          },
        },
      }}
    ></Toaster>
  )
}
