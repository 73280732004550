import { gql } from '@apollo/client'

const VIDEO_FRAGMENT = gql`
  fragment VideoFragment on Video {
    id
    uri
    title
    content
    featuredImage {
      node {
        mediaItemUrl
        altText
      }
    }
    video_acf {
      category
      winner
      youtubeUrl
    }
    videoCategories {
      nodes {
        id
        databaseId
        name
      }
    }
    author {
      node {
        name
      }
    }
  }
`

export const INCREMENT_VIEWS = gql`
  mutation incrementViews($id: ID) {
    incrementPostViews(input: { id: $id }) {
      views
    }
  }
`

export const CULT_FRAGMENT_SHORT = gql`
  fragment CultFragmentShort on Culte {
    id
    title
    uri
    excerpt
    contentTypeName
    views {
      views
    }
    culteCategories {
      nodes {
        name
      }
    }
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
  }
`
export const CREATION_FRAGMENT_SHORT = gql`
  fragment CreationFragmentShort on Creation {
    id
    title
    uri
    excerpt
    contentTypeName
    views {
      views
    }
    creationCategories {
      nodes {
        name
      }
    }
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    winner {
      winner
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
  }
`
export const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    id
    title
    uri
    slug
    content
    excerpt
    contentTypeName
    redactor: author {
      node {
        name
      }
    }
    featuredImage {
      node {
        mediaItemUrl
        altText
      }
    }
  }
`

export const CTA_FRAGMENT = gql`
  fragment CTAFragment on CallToCreations {
    id
    title
    uri
    slug
    content
    excerpt
    contentTypeName
    redactor: author {
      node {
        name
      }
    }
    featuredImage {
      node {
        mediaItemUrl
        altText
      }
    }
  }
`

export const CRITIC_FRAGMENT_SHORT = gql`
  fragment CriticFragmentShort on Critique {
    id
    title
    uri
    excerpt
    contentTypeName
    views {
      views
    }
    categories {
      nodes {
        name
      }
    }
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
  }
`

export const SURVEY_FRAGMENT_SHORT = gql`
  fragment SurveyFragmentShort on Survey {
    id
    title
    uri
    excerpt
    contentTypeName
    views {
      views
    }
    surveyCategories {
      nodes {
        name
      }
    }
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
  }
`

const SURVEY_FRAGMENT = gql`
  fragment SurveyFragment on Survey {
    id
    databaseId
    slug
    uri
    # content
    excerpt
    contentTypeName
    views {
      views
    }
    surveyCategories {
      nodes {
        name
      }
    }
    writer: auteurs {
      auteur {
        ... on Auteur {
          title
        }
      }
    }
    redactor: author {
      node {
        name
      }
    }
    author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
    title
  }
`

const CRITIC_FRAGMENT = gql`
  fragment CriticFragment on Critique {
    id
    databaseId
    slug
    uri
    # content
    excerpt
    contentTypeName
    views {
      views
    }
    categories {
      nodes {
        name
      }
    }
    writer: auteurs {
      auteur {
        ... on Auteur {
          title
        }
      }
    }
    redactor: author {
      node {
        name
      }
    }
    author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        sourceUrl
        altText
      }
    }
    title
  }
`

const CULT_FRAGMENT = gql`
  fragment CultFragment on Culte {
    id
    databaseId
    slug
    uri
    content: cultesContent {
      excerpt {
        content
        title
      }
      intro
      summary {
        content
        title
      }
      why {
        title
        whyitems {
          content
        }
      }
    }
    excerpt
    views {
      views
    }
    contentTypeName
    writer: auteurs {
      auteur {
        ... on Auteur {
          title
        }
      }
    }
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    featuredImage {
      node {
        altText
        uri
        sourceUrl
      }
    }
    title
  }
`

const CREATION_FRAGMENT = gql`
  fragment CreationFragment on Creation {
    id
    databaseId
    slug
    uri
    views {
      views
    }
    content
    excerpt
    contentTypeName
    redactor: author {
      node {
        name
      }
    }
    premium {
      premium
    }
    winner {
      winner
    }
    featuredImage {
      node {
        altText
        uri
        sourceUrl
      }
    }
    title
  }
`

export const FETCH_CRITIC_BY_SLUG = gql`
  ${CRITIC_FRAGMENT}
  query fetchCritic($slug: String!) {
    post: critiqueBy(slug: $slug) {
      ...CriticFragment
    }
  }
`
export const FETCH_CULT_BY_SLUG = gql`
  ${CULT_FRAGMENT}
  query fetchCult($slug: String!) {
    post: culteBy(slug: $slug) {
      ...CultFragment
    }
  }
`
export const FETCH_SURVEY_BY_SLUG = gql`
  ${SURVEY_FRAGMENT}
  query fetchSurvey($slug: String!) {
    post: surveyBy(slug: $slug) {
      ...SurveyFragment
    }
  }
`
export const FETCH_CREATION_BY_SLUG = gql`
  ${CREATION_FRAGMENT}
  query fetchCreation($slug: String!) {
    post: creationBy(slug: $slug) {
      ...CreationFragment
    }
  }
`
export const FETCH_EVENT_BY_SLUG = gql`
  ${EVENT_FRAGMENT}
  query fetchEvent($slug: String!) {
    post: eventBy(slug: $slug) {
      ...EventFragment
    }
  }
`
export const FETCH_CTA_BY_SLUG = gql`
  ${CTA_FRAGMENT}
  query fetchCTA($slug: String!) {
    post: callToCreationsBy(slug: $slug) {
      ...CTAFragment
    }
  }
`
export const FETCH_VIDEO_BY_SLUG = gql`
  ${VIDEO_FRAGMENT}
  query fetchVideo($slug: String!) {
    post: videoBy(slug: $slug) {
      ...VideoFragment
    }
  }
`

export const FETCH_SURVEY = gql`
  ${SURVEY_FRAGMENT}
  query fetchSurvey($id: ID!) {
    post: survey(id: $id) {
      ...SurveyFragment
    }
  }
`

export const FETCH_CRITIC = gql`
  ${CRITIC_FRAGMENT}
  query fetchCritic($id: ID!) {
    post: critique(id: $id) {
      ...CriticFragment
    }
  }
`
export const FETCH_CULT = gql`
  ${CULT_FRAGMENT}
  query fetchCult($id: ID!) {
    post: culte(id: $id) {
      ...CultFragment
    }
  }
`
export const FETCH_CREATION = gql`
  ${CREATION_FRAGMENT}
  query fetchCreation($id: ID!) {
    post: creation(id: $id) {
      ...CreationFragment
    }
  }
`

const COMMENT_FRAGMENT = gql`
  fragment commentFragment on Comment {
    content
    id
    databaseId
    date
    author {
      node {
        name
        ... on User {
          id
          email
          firstName
          lastName
          subscriberData {
            avatarImage
            variantAvatar
          }
          redactor {
            img {
              sourceUrl(size: THUMBNAIL)
              altText
            }
          }
        }
      }
    }
    parent {
      node {
        id
      }
    }
    replies(where: { orderby: COMMENT_DATE, order: ASC }) {
      nodes {
        content
        id
        databaseId
        date
        author {
          node {
            name
            ... on User {
              id
              email
              firstName
              lastName
              subscriberData {
                avatarImage
                variantAvatar
              }
              redactor {
                img {
                  sourceUrl(size: THUMBNAIL)
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`

export const FETCH_COMMENTS = gql`
  ${COMMENT_FRAGMENT}
  query fetchComments(
    $postId: ID!
    $limit: Int
    $after: String
    $orderBy: CommentsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    comments(
      first: $limit
      after: $after
      where: {
        contentId: $postId
        orderby: $orderBy
        order: $order
        parent: null
      }
    ) {
      nodes {
        ...commentFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const FETCH_POST = gql`
  ${CRITIC_FRAGMENT}
  ${CULT_FRAGMENT}
  ${CREATION_FRAGMENT}
  query fetchPost($id: String!) {
    post: contentNode(
      where: { id: $id, contentTypes: [CRITIQUES, CULTES, CREATIONS] }
    ) {
      ...CriticFragment
      ...CreationFragment
      ...CultFragment
    }
  }
`
export const HOME_POSTS = gql`
  ${CRITIC_FRAGMENT}
  ${CULT_FRAGMENT}
  ${CREATION_FRAGMENT}
  query searchContent($after: String) {
    posts: contentNodes(
      first: 8
      after: $after
      where: {
        orderby: { field: DATE, order: DESC }
        contentTypes: [CRITIQUES, LES_MODERNES, NOUVELLES_VOIX]
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ...CriticFragment
        ...CreationFragment
        ...CultFragment
      }
    }
  }
`

export const FETCH_CULTS = gql`
  ${CULT_FRAGMENT_SHORT}
  query getCults(
    $after: String
    $limit: Int
    $categoryId: Int
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    posts: cultes(
      first: $limit
      after: $after
      where: {
        orderby: { field: $orderBy, order: $order }
        culteCategoryId: $categoryId
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ...CultFragmentShort
      }
    }
  }
`

export const FETCH_SURVEYS = gql`
  ${SURVEY_FRAGMENT_SHORT}
  query getSurveys(
    $after: String
    $limit: Int
    $categoryId: Int
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    posts: surveys(
      first: $limit
      after: $after
      where: {
        orderby: { field: $orderBy, order: $order }
        surveyCategoryId: $categoryId
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ...SurveyFragmentShort
      }
    }
  }
`

export const FETCH_CRITICS = gql`
  ${CRITIC_FRAGMENT_SHORT}
  query getCritiques(
    $after: String
    $limit: Int
    $categoryId: Int
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    posts: critiques(
      first: $limit
      after: $after
      where: {
        orderby: { field: $orderBy, order: $order }
        categoryId: $categoryId
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ...CriticFragmentShort
      }
    }
  }
`

export const FETCH_CREATIONS = gql`
  ${CREATION_FRAGMENT_SHORT}
  query getCreations(
    $after: String
    $limit: Int
    $categoryId: Int
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    posts: creations(
      first: $limit
      after: $after
      where: {
        orderby: { field: $orderBy, order: $order }
        creationCategoryId: $categoryId
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ...CreationFragmentShort
      }
    }
  }
`

export const FETCH_ARCHIVES = gql`
  query getArchives(
    $after: String
    $first: Int
    $last: Int
    $before: String
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
    $category: Int
  ) {
    archives(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        orderby: { field: $orderBy, order: $order }
        archiveCategoryId: $category
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        archivePdf {
          archiveEpub {
            mediaItemUrl
            title
            sourceUrl
          }
          pdf {
            mediaItemUrl
            sourceUrl
            title
          }
        }
      }
    }
  }
`
export const FETCH_VIDEOS = gql`
  ${VIDEO_FRAGMENT}
  query getVideos(
    $after: String
    $first: Int
    $last: Int
    $category: Int
    $before: String
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    videos(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        orderby: { field: $orderBy, order: $order }
        videoCategoryId: $category
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        ...VideoFragment
      }
    }
  }
`
